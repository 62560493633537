body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.ant-layout-header .logo {
  float: left;
  padding: 8px 0;
  width: 200px;
  height: 60px;
  text-align: center;
  cursor: pointer;
}

.ant-layout-header .logo img {
  width: 80%;
  vertical-align: baseline;
}

.hidden {
  display: none !important;
}

.tools-wrapper {
  padding: 0;
}

.tools-wrapper .ant-btn {
  margin-top: 8px;
  margin-right: 8px;
}
.ant-table-bordered .ant-table-tbody > tr > td{
  text-align: center;
}
.tools-wrapper .ant-btn:last-child {
  margin-right: 0;
}

.table-wrapper {
  margin-top: 20px;
  margin-bottom: 50px;
}

.table-wrapper .ant-table {
  overflow: auto;
}

.table-wrapper .ant-table tbody tr {
  cursor: pointer;
}
.green {
  color: #65a037;
}

.red {
  color: #bb3627;
}
.table-striped{
  color: #fd1900;
}
.table-wrapper .ant-table-bordered .ant-table-thead>tr>th {
  white-space: nowrap;
}

.ant-select-dropdown-menu-item {
  white-space: normal !important;
}

.detail-form-wrapper>.ant-row>.ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
  width: 300px;
  margin-right: 10px;
}

.detail-form-wrapper .readonly-text {
  padding: 9px 11px;
  white-space: normal;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}

.detail-form-wrapper .ant-upload-list-item-done .ant-upload-list-item-info>span {
  height: 100%;
}

.build-modal-detail .detail-form-wrapper {
  width: 100%;
}

.textarea-normalArea {
  resize: none;
}

.ant-form-item-control {
  z-index: 99;
}

.restDay-div {
  width: 100%;
  text-align: center;
}
.ant-back-top{
  z-index: 1000;
}
.previewImg-wrap .img-wrap{
  width: 100%;
}
.previewImg-wrap .img-wrap img{
  width: 100%;
  vertical-align: bottom;
}
.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}
.ant-carousel .slick-dots li button{
  background: #000 !important;
}
.down-wrap{
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.cform-item-btn .ant-form-item-label label {
  visibility: hidden;
}
.ant-menu-horizontal {
  white-space: normal !important;
}
.detail-language-tab .detail-language-tab-line-in, .detail-language-tab .detail-language-tab-line-out {
  cursor: pointer;
}
.detail-hover-txt:hover{
  cursor: pointer;
  color: #1890ff;
}
.ant-modal-confirm-content{
  max-height:220px;
  overflow:auto;
}
.ant-modal-confirm .ant-modal-body{
  padding:32px 10px 24px;
}